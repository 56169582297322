import React, { useEffect, useState } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import {
  CircularProgress,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  MenuItem,
  TextField,
  FormControl,
  Select,
  Checkbox,
  ListItemText
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import CaseForm from "./CaseForm";
import moment from "moment";
import { AppBaseUrl } from "../appdata/appData";
import AutohideSnackbar from "./autohideSnackbar";
import AppGlobalStaticData from "../appdata/appStaticData";

const columnOptions = [
  { value: 'fileNo', label: 'File No' },
  { value: 'caseNo', label: 'Case No' },
  { value: 'IANo', label: 'IA No' },
  { value: 'CNRNo', label: 'CNR No' },
  { value: 'clientName', label: 'Client Name' },
  { value: 'appearingFor', label: 'Appearing For' },
  { value: 'mobile', label: 'Mobile' },
  { value: 'mobile2', label: 'Mobile 2' },
  { value: 'nextDate', label: 'Next Date' },
  { value: 'status', label: 'Status' }
];

const CaseList = ({ Type, startdate, enddate, casestatusParam }) => {
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedcaseId, setSelectedcaseId] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(enddate || "");
  const [courtFilter, setCourtFilter] = useState("");
  const [courtPlaceFilter, setCourtPlaceFilter] = useState("");
  const [groupedCourts, setGroupedCourts] = useState({});
  const [casestatus, setCaseStatus] = useState(casestatusParam || []);
  const [caseStatusDropDown, setCaseStatusDropdown] = useState(0);
  const [snackbarProperties, setSnackbarProperties] = useState({
    duration: 0,
    message: "",
    serverity: "",
  });
  const [totalCases, setTotalCases] = useState(0);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 25,
  });
  const [selectedColumns, setSelectedColumns] = useState(columnOptions.map(option => option.value));
  const globalData = new AppGlobalStaticData();
  const caseStatusList = [{ id: 0, name: "select" }, { id: 1, name: "Disposed" }, { id: 2, name: "Decreed" }];
  useEffect(() => {
    setEndDate(enddate);
    setCaseStatus(casestatusParam);
  }, [Type, casestatusParam, enddate]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchCaseList();
      if (Type === "List") {
        await fetchCourts();
      }

    };

    if (!loading) {
      if ((Type === "Edit" && endDate) || Type === "List" || Type === "Disposed-Decreed") {
        fetchData();
      }
    }
  }, [Type, casestatus, endDate, startDate, courtFilter, courtPlaceFilter,paginationModel]);



  const fetchCaseList = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${AppBaseUrl}/api/v1/case-details/list`, {
        params: {
          start_date: startDate ? moment(startDate).format("YYYY-MM-DD") : startdate ? moment(startdate).format("YYYY-MM-DD") :  undefined,
          end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
          court_id: courtFilter,
          court_place: courtPlaceFilter,
          api_token: "ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo",
          case_status: JSON.stringify(casestatus),
          page: paginationModel.page + 1, // Convert to one-based index for API
          per_page: paginationModel.pageSize,
        },
      });
      if (response.data.status === "Success") {
        const caseListWithSno = response.data.data.data.map((item, index) => ({
          id: item.id,
          sno: paginationModel.page * paginationModel.pageSize + index + 1,
          ...item,
        }));
        setCases(caseListWithSno);
        setTotalCases(response.data.data.total);
        setSnackbarProperties({
          duration: globalData.Duration,
          message: globalData.CaseListSuccess,
          serverity: globalData.SeveritySuccess,
        });
      } else {
        setSnackbarProperties({
          duration: globalData.Duration,
          message: globalData.CaseListFailed,
          serverity: globalData.SeverityError,
        });
        console.error("Failed to fetch cases");
      }
    } catch (error) {
      setSnackbarProperties({
        duration: globalData.Duration,
        message: globalData.CaseListFailed,
        serverity: globalData.SeverityError,
      });
      console.error("Error fetching cases:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCourts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${AppBaseUrl}/api/v1/court/list`, {
        params: {
          api_token: "ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo",
        }
      });
      if (response.data.status === "Success") {
        const courts = response.data.data;
        const grouped = courts.reduce((acc, court) => {
          if (!acc[court.Place]) {
            acc[court.Place] = [];
          }
          acc[court.Place].push(court);
          return acc;
        }, {});
        setGroupedCourts(grouped);
      } else {
        console.error("Failed to fetch courts");
      }
    } catch (error) {
      setSnackbarProperties({
        duration: globalData.Duration,
        message: globalData.CourtListFailed,
        serverity: globalData.SeverityError,
      });
      console.error("Error fetching courts:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = (id) => {
    setSelectedcaseId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedcaseId(null);
    fetchCaseList();
  };

  const handleEdit = (id) => {
    setSelectedcaseId(id);
    setOpen(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const response = await axios.delete(`${AppBaseUrl}/api/v1/case-details/${id}/delete?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`);
      if (response.data.status === "Success") {
        setSnackbarProperties({
          duration: globalData.Duration,
          message: globalData.DeleteCaseSuccess,
          serverity: globalData.SeveritySuccess,
        });
      } else {
        setSnackbarProperties({
          duration: globalData.Duration,
          message: globalData.DeleteCaseFailed,
          serverity: globalData.SeverityError,
        });
        console.error("Failed to delete case");
      }
    } catch (error) {
      setSnackbarProperties({
        duration: globalData.Duration,
        message: globalData.DeleteCaseFailed,
        serverity: globalData.SeverityError,
      });
      console.error("Error deleting case:", error);
    } finally {
      setLoading(false);
      fetchCaseList();
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleFilterSubmit = () => {
    fetchCaseList();
  };

  const handleColumnChange = (event) => {
    setSelectedColumns(event.target.value);
  };

  const handleCaseStatusDropDown = (e) => {
    setCaseStatusDropdown(e.target.value);
    if (e.target.value === 0) {
      setCaseStatus([1, 2]);

    }
    else {
      setCaseStatus([e.target.value]);
    }
  }

  const handleDownloadDocument = async () => {
    try {
      setSnackbarProperties({
        duration: globalData.Duration,
        message: globalData.DownloadStarted,
        serverity: globalData.SeverityInfo,
      });


      const response = await axios.get(`${AppBaseUrl}/api/v1/case-details/download`, {
        params: {
          start_date: startDate ? moment(startDate).format("YYYY-MM-DD") :startdate ? moment(startdate).format("YYYY-MM-DD") : undefined,
          end_date: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
          court_id: courtFilter,
          court_place: courtPlaceFilter,
          api_token: "ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo",
          columns: selectedColumns
        },
        responseType: "blob", // Important to handle binary data
      });
      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "case_details.docx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setSnackbarProperties({
          duration: globalData.Duration,
          message: globalData.DownloadSuccess,
          serverity: globalData.SeveritySuccess,
        });
      } else {
        setSnackbarProperties({
          duration: globalData.Duration,
          message: globalData.DownloadFailed,
          serverity: globalData.SeverityError,
        });
        console.error("Failed to download document:", response.statusText);
      }
    } catch (error) {
      setSnackbarProperties({
        duration: globalData.Duration,
        message: globalData.DownloadFailed,
        serverity: globalData.SeverityError,
      });
      if (error.response) {
        console.error("Server Error:", error.response.data);
      } else if (error.request) {
        console.error("Network Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const columns = [
    { field: "sno", headerName: "S.No", width: 90, sortable: false },
    { field: "fileNo", headerName: "File No", width: 100 },
    { field: "caseNo", headerName: "Case No", width: 100 },
    { field: "IANo", headerName: "IANO", width: 100 },
    { field: 'CNRNo', headerName: 'CNR No', width: 100 },
    { field: "clientName", headerName: "Client Name", width: 150 },
    { field: "appearingFor", headerName: "Appearing For", width: 150 },
    { field: "mobile", headerName: "Mobile 1", width: 130 },
    { field: "mobile2", headerName: "Mobile 2", width: 130 },
    { field: "caseStatus", headerName: "case Status", width: 150, valueFormatter: (params) => params === 1 ? "Disposed" : "Decreed" },
    {
      field: "prevDate",
      headerName: "Prev Date",
      width: 100,
      valueFormatter: (params) => formatDate(params),
    },
    {
      field: "nextDate",
      headerName: "Next Date",
      width: 100,
      valueFormatter: (params) => formatDate(params),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleEdit(params.id)} color="primary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.id)} color="secondary">
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];



  const filteredColumns = Type === "Disposed-Decreed" ? columns.filter(col => !["IANo", "appearingFor", "prevDate", "nextDate,mobile2"].includes(col.field)) : columns.filter(col => !["caseStatus"].includes(col.field));

  const GridFilterElement = () => {
    if (Type === "List") {
      return (
        <>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3}>
              <TextField
                select
                label="Court Place"
                value={courtPlaceFilter}
                onChange={(e) => setCourtPlaceFilter(e.target.value)}
                fullWidth
                margin="normal"
              >
                {Object.keys(groupedCourts).map((courtPlace) => (
                  <MenuItem key={courtPlace} value={courtPlace}>
                    {courtPlace}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                select
                label="Court Name"
                value={courtFilter}
                onChange={(e) => setCourtFilter(e.target.value)}
                fullWidth
                margin="normal"
                disabled={!courtPlaceFilter} // Disable if no court place is selected
              >
                {courtPlaceFilter &&
                  groupedCourts[courtPlaceFilter].map((court) => (
                    <MenuItem key={court.id} value={court.id}>
                      {court.CourtName}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={2}>
              <DatePickerComponent
                placeholder="Start Date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                format="dd/MM/yyyy"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <DatePickerComponent
                placeholder="End Date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                format="dd/MM/yyyy"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFilterSubmit}
                fullWidth
                style={{ marginTop: "25px" }}
              >
                Apply Filters
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth margin="normal">
                <Select
                  multiple
                  value={selectedColumns}
                  onChange={handleColumnChange}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {columnOptions.map((column) => (
                    <MenuItem key={column.value} value={column.value}>
                      <Checkbox checked={selectedColumns.indexOf(column.value) > -1} />
                      <ListItemText primary={column.label} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}></Grid>

            <Grid item xs={12} sm={2}>
              <Button variant="contained" color="primary" onClick={() => handleOpen(null)}>
                Create Case
              </Button>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button variant="contained" color="secondary" onClick={handleDownloadDocument}>
                Download
              </Button>
            </Grid>
          </Grid>
        </>
      );
    }
  };

  const CaseStatusDropDownUI = () => {
    if (Type === "Disposed-Decreed") {
      return (
        <>
          <Grid item xs={12} sm={3}>
            <TextField
              select
              label="Case status List"
              value={caseStatusDropDown}
              onChange={handleCaseStatusDropDown}
              fullWidth
              margin="normal"
            >
              {caseStatusList.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </>

      );
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <GridFilterElement />
      <CaseStatusDropDownUI />
      <div style={{ height: "auto", width: "100%", margin: "auto" }}>
        <DataGrid
  rows={cases}
  columns={filteredColumns}
  pageSizeOptions={[10, 25, 50, 100]} // Add 100 as an option
  pageSize={paginationModel} // Set the default page size to 100
  rowCount={totalCases}
  paginationMode="server"
  paginationModel={paginationModel}
  onPaginationModelChange={setPaginationModel}
  loading={loading}
/>

      </div>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{selectedcaseId ? "Edit Case" : "Create Case"}</DialogTitle>
        <DialogContent>
          <CaseForm id={selectedcaseId} onClose={handleClose} Type={Type} caseStatusList={caseStatusList} />
        </DialogContent>
      </Dialog>
      <AutohideSnackbar
        duration={snackbarProperties.duration}
        message={snackbarProperties.message}
        severity={snackbarProperties.serverity}
      />
    </>
  );
};

export default CaseList;
