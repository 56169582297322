import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { TextField, Button, CircularProgress, Box, Grid, MenuItem } from '@mui/material';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import moment from 'moment';
import { AppBaseUrl } from '../appdata/appData';
import AutohideSnackbar from './autohideSnackbar';
import AppGlobalStaticData from '../appdata/appStaticData';
import Autocomplete from '@mui/material/Autocomplete';

const formatDateForDisplay = (dateString) => {
  if (!dateString) return '';
  return moment(dateString, 'YYYY-MM-DD').format('DD/MM/YYYY');
};

const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  return moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD');
};

const CaseForm = ({ id, onClose, Type, caseStatusList }) => {
  const [cases, setCases] = useState({
    fileNo: '',
    caseNo: '',
    IANo: '',
    CNRNo: '',
    clientName: '',
    appearingFor: '',
    mobile: '',
    mobile2: '',
    prevDate: '',
    nextDate: '',
    status: '',
    courtID: 0,
    caseStatus: 0
  });
  const [courts, setCourts] = useState([]);
  const [appearingList, setAppearingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarProperties, setSnackbarProperties] = useState({
    duration: 0,
    message: '',
    serverity: ''
  });

  const isSubmitting = useRef(false);  // useRef to manage form submission state
  const globalData = new AppGlobalStaticData();
  //const caseStatusList = [{ id: 0, name: "select" }, { id: 1, name: "Disposed" }, { id: 2, name: "Decreed" }];
  const fetchCourts = async () => {
    try {
      const response = await axios.get(`${AppBaseUrl}/api/v1/court/list?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`);
      if (response.data.status === 'Success') {
        setCourts(response.data.data);
      } else {
        setSnackbarProperties({
          duration: globalData.Duration,
          message: globalData.CourtListFailed,
          serverity: globalData.SeverityError
        });
        console.error('Failed to fetch courts');
      }
    } catch (error) {
      setSnackbarProperties({
        duration: globalData.Duration,
        message: globalData.CourtListFailed,
        serverity: globalData.SeverityError
      });
      console.error('Error fetching courts:', error);
    }
  };

  const appearingLists = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${AppBaseUrl}/api/v1/config/appearinglist?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`);
      if (response.data.status === 'Success') {
        setAppearingList(response.data.data);
        setLoading(false);
      }

    } catch (error) {
      setSnackbarProperties({
        duration: globalData.Duration,
        message: globalData.AppearingListFailed,
        serverity: globalData.SeverityError
      });
    }
  }

  useEffect(() => {
    appearingLists();
    fetchCourts();


    if (id) {
      setLoading(true);
      axios.get(`${AppBaseUrl}/api/v1/case-details/${id}/get?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`)
        .then(response => {
          if (response.data.status === 'Success') {
            const caseData = response.data.data;
            if (Type === "Edit") {
              caseData.prevDate = caseData.nextDate;
              caseData.nextDate = "";
            }
            setCases({
              ...caseData,
              prevDate: formatDateForDisplay(caseData.prevDate),
              nextDate: formatDateForDisplay(caseData.nextDate)
            });
          } else {
            setSnackbarProperties({
              duration: globalData.Duration,
              message: globalData.FetchCaseFailed,
              serverity: globalData.SeverityError
            });
            console.error('Failed to fetch case details');
          }
        })
        .catch(error => {
          console.error('Error fetching case details:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCases({
      ...cases,
      [name]: value
    });
  };



  const handleCourtChange = (e) => {
    setCases({
      ...cases,
      courtID: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting.current) return;

    isSubmitting.current = true;

    const formattedCases = {
      ...cases,
      prevDate: formatDateForInput(cases.prevDate),
      nextDate: formatDateForInput(cases.nextDate),
    };

    try {
      const submitRequest = id
        ? axios.put(`${AppBaseUrl}/api/v1/case-details/${id}/update?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`, formattedCases)
        : axios.post(`${AppBaseUrl}/api/v1/case-details/create?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`, formattedCases);

      const response = await submitRequest;

      if (response.data.status === 'Success') {
        onClose();
        setSnackbarProperties({
          duration: globalData.Duration,
          message: id ? globalData.EditCaseSuccess : globalData.AddCaseSuccess,
          serverity: globalData.SeveritySuccess
        });
      } else {
        setSnackbarProperties({
          duration: globalData.Duration,
          message: id ? globalData.EditCaseFailed : globalData.AddCaseFailed,
          serverity: globalData.SeverityError
        });
        console.error(`Failed to ${id ? 'update' : 'create'} case`);
      }
    } catch (error) {
      console.error(`Error ${id ? 'updating' : 'creating'} case:`, error);
    } finally {
      isSubmitting.current = false;
    }
  };

  const CaseEditField = () => {
    if (id) {
      return (
        <>
          <Grid item xs={12}>
            <TextField
              select
              label="Case Status"
              name="caseStatus"
              value={cases.caseStatus}
              onChange={handleChange}
              fullWidth
              margin="normal"
            >
              {caseStatusList.map((status) => (
                <MenuItem key={status.id} value={status.id}>
                  {status.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid></>

      );
    }
  };



  if (loading) {
    return <CircularProgress />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="File No"
            name="fileNo"
            value={cases.fileNo}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Case No"
            name="caseNo"
            value={cases.caseNo}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="IANo"
            name="IANo"
            value={cases.IANo}
            onChange={handleChange}
            fullWidth
            margin="normal"

          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="CNRNo"
            name="CNRNo"
            value={cases.CNRNo}
            onChange={handleChange}
            fullWidth
            margin="normal"

          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Client Name"
            name="clientName"
            value={cases.clientName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12}>


        <Autocomplete
  freeSolo
  id="appearing-for-autocomplete"
  disableClearable
  value={cases.appearingFor}
  onChange={(event, newValue) => {
    console.log("Selected value:", newValue);
    setCases({ ...cases, appearingFor: newValue });
  }}
  onInputChange={(event, newInputValue) => {
    console.log("Typed value:", newInputValue);
    setCases({ ...cases, appearingFor: newInputValue });
  }}
  options={appearingList.map((option) => option.appeaingName)}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Appearing For"
      name="appearingFor"
      InputProps={{
        ...params.InputProps,
        type: 'search',
      }}
    />
  )}
/>

        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Mobile"
            name="mobile"
            value={cases.mobile}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Mobile 2"
            name="mobile2"
            value={cases.mobile2}
            onChange={handleChange}
            fullWidth
            margin="normal"

          />
        </Grid>
        <Grid item xs={12}>
          <DatePickerComponent
            placeholder="Previous Date"
            name="prevDate"
            value={cases.prevDate}
            onChange={(e) => setCases({ ...cases, prevDate: e.target.value })}
            format="dd/MM/yyyy"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <DatePickerComponent
            placeholder="Next Date"
            name="nextDate"
            value={cases.nextDate}
            onChange={(e) => setCases({ ...cases, nextDate: e.target.value })}
            format="dd/MM/yyyy"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Status"
            name="status"
            value={cases.status}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            label="Court Name"
            name="courtID"
            value={cases.courtID}
            onChange={handleCourtChange}
            fullWidth
            margin="normal"
            required
          >
            {courts.map((court) => (
              <MenuItem key={court.id} value={court.id}>
                {court.CourtName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <CaseEditField />
        <Grid item xs={12}>
          <Box mt={2}>
            <Button variant="contained" color="primary" type="submit" fullWidth disabled={isSubmitting.current}>
              {id ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <AutohideSnackbar duration={snackbarProperties.duration} message={snackbarProperties.message} severity={snackbarProperties.serverity} ></AutohideSnackbar>
    </form>
  );
};

export default CaseForm;
