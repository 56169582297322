// src/pages/HomePage.js
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Cookies from 'js-cookie';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Container,
  Box,
  CircularProgress
} from "@mui/material";
import axios from "axios";
import AppGlobalStaticData from "../appdata/appStaticData";
import { AppBaseUrl } from "../appdata/appData";
import AutohideSnackbar from "../components/autohideSnackbar";

const HomePage = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState([
    {
      Count: 0,
      URL: "/case/list",
      Heading: "Case Count",
      section: "caseCount"
    },
    {
      Count: 0,
      URL: "/case/edit",
      Heading: "Pending",
      section: "pendingCaseCount"
    },
    {
      Count: 0,
      URL: "/case/disposed-decreed",
      Heading: "Decreed",
      section: "decreedCaseCount"
    },
    {
      Count: 0,
      URL: "/case/disposed-decreed",
      Heading: "Disposed",
      section: "disposedCaseCount"
    },
    {
      Count: 0,
      URL: "/courts",
      Heading: "Court",
      section: "CourtList"
    }
  ]);
  const [snackbarProperties, setSnackbarProperties] = useState({
    duration: 0,
    message: '',
    severity: ''
  });
  const [loading, setLoading] = useState(false);

  const globalData = new AppGlobalStaticData();

  useEffect(() => {
    const token = Cookies.get('auth_token');
    if (!token) {
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } else {
      fetchDashboardData();
    }
  }, [navigate]);

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${AppBaseUrl}/api/v1/case-details/dashboard`, {
        params: {
          api_token: "ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo",
        },
      });

      if (response.data.status === "Success") {
        const updatedData = dashboardData.map((item) => ({
          ...item,
          Count: response.data.data[item.section] || 0
        }));

        setDashboardData(updatedData);
        setSnackbarProperties({
          duration: globalData.Duration,
          message: globalData.HomePageDashboardContentFailed,
          severity: globalData.SeveritySuccess,
        });
      } else {
        setSnackbarProperties({
          duration: globalData.Duration,
          message: globalData.HomePageDashboardContentFailed,
          severity: globalData.SeverityError,
        });
        console.error("Failed to fetch cases");
      }
    } catch (error) {
      setSnackbarProperties({
        duration: globalData.Duration,
        message: globalData.HomePageDashboardContentFailed,
        severity: globalData.SeverityError,
      });
      console.error("Error fetching cases:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
     <Grid container spacing={2} mb={2} mt={3} justifyContent="center">
        {dashboardData.map((value, index) => (
          <Grid item xs={12} sm={6} md={4} lg={2.25} key={index}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={3}
              mb={3}
            >
              <Link to={value.URL} style={{ textDecoration: 'none' }}>
                <Card  sx={{ border: '1px solid #ccc', borderRadius: '8px' }}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {value.Heading}
                    </Typography>
                    <Typography variant="h3" color="textSecondary" component="h2">
                      {value.Count}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Box>
          </Grid>
        ))}
      </Grid>
      <AutohideSnackbar
        duration={snackbarProperties.duration}
        message={snackbarProperties.message}
        severity={snackbarProperties.severity}
      />
    </Container>
  );
};

export default HomePage;
