// src/App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import UserListPage from './pages/UserListPage';
import CourtListPage from './pages/CourtListPage';
import HomePage from './pages/HomePage';
import Login from './components/Login';
import CaseListPage from './pages/CaseListPage';
import EditCaseListPage from './pages/EditCaseListPage';
import DisposedDecreedListPage from './pages/Disposed-Decreed';

import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-calendars/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';


function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Router>
      <Layout
        toggleSidebar={toggleSidebar}
        isSidebarOpen={isSidebarOpen}
        setSidebarOpen={setSidebarOpen}
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/users" element={<UserListPage />} />
          <Route path="/courts" element={<CourtListPage />} />
          <Route path="/case/list" element={<CaseListPage />} />
          <Route path="/case/edit" element={<EditCaseListPage />} />
          <Route path="/case/disposed-decreed" element={<DisposedDecreedListPage />} />
          
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
