// src/components/Layout.js
import React from 'react';
import { useLocation } from 'react-router-dom';
import AppBarComponent from './AppBar';
import Sidebar from './Sidebar';

const Layout = ({ children, toggleSidebar, isSidebarOpen, setSidebarOpen }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <>
      {!isLoginPage && <AppBarComponent toggleSidebar={toggleSidebar} />}
      {!isLoginPage && <Sidebar open={isSidebarOpen} onClose={() => setSidebarOpen(false)} />}
      {children}
    </>
  );
};

export default Layout;
