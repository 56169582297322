// src/components/AppBar.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const AppBarComponent = ({ toggleSidebar }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Add your logout logic here
    Cookies.remove('auth_token');
    navigate('/login');
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: '#F64E31' }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleSidebar}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Case Register
        </Typography>
        <Button color="inherit" onClick={handleLogout}>Logout</Button>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
