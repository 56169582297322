export default class AppGlobalStaticData {
  Duration = 1000;
  SeveritySuccess = "success";
  SeverityError = "error";
  SeverityInfo = "info";

  LoginSuccess = "Login successful";
  LoginFailed = "Login failed. Check username and password.";

  CaseListSuccess = "Case list retrieved successfully.";
  CaseListFailed = "Failed to retrieve case list.";
  AddCaseSuccess = "Case record was created successfully.";
  EditCaseSuccess = "Case record was updated successfully.";
  DeleteCaseSuccess = "Case record was deleted successfully.";
  AddCaseFailed = "Failed to create case record.";
  EditCaseFailed = "Failed to update case record.";
  DeleteCaseFailed = "Failed to delete case record.";
  FetchCaseFailed = "Failed to retrieve case record.";

  UserListSuccess = "User list retrieved successfully.";
  UserListFailed = "Failed to retrieve user list.";
  AddUserSuccess = "User record was created successfully.";
  EditUserSuccess = "User record was updated successfully.";
  DeleteUserSuccess = "User record was deleted successfully.";
  FetchUserSuccess = "User record was retrieved successfully.";
  AddUserFailed = "Failed to create user record.";
  EditUserFailed = "Failed to update user record.";
  DeleteUserFailed = "Failed to delete user record.";
  FetchUserFailed = "Failed to retrieve user record.";

  CourtListSuccess = "Court list retrieved successfully.";
  CourtListFailed = "Failed to retrieve court list.";
  AddCourtSuccess = "Court record was created successfully.";
  EditCourtSuccess = "Court record was updated successfully.";
  DeleteCourtSuccess = "Court record was deleted successfully.";
  FetchCourtSuccess = "Court record was retrieved successfully.";
  AddCourtFailed = "Failed to create court record.";
  EditCourtFailed = "Failed to update court record.";
  DeleteCourtFailed = "Failed to delete court record.";
  FetchCourtFailed = "Failed to retrieve court record.";

  DownloadStarted = "Report download started...";
  DownloadSuccess = "Report downloaded successfully.";
  DownloadFailed = "Failed to download report.";

  AppearingListFailed = "Failed to fetch appearing list.";

  HomePageDashboardContentSuccess = "Dashboard content loaded successfully.";
  HomePageDashboardContentFailed = "Failed to load dashboard content.";
}
