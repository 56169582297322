// src/pages/CourtForm.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, CircularProgress, Box, Grid } from '@mui/material';
import {AppBaseUrl} from '../appdata/appData';
import AutohideSnackbar from './autohideSnackbar';
import AppGlobalStaticData from '../appdata/appStaticData';

const CourtForm = ({ id, onClose }) => {
  const [court, setCourt] = useState({
    CourtName: '',
    isactive: 1,
    Place: ''
  });
  const [loading, setLoading] = useState(false);
  const [snackbarProperties,  setSnackbarProperties] = useState({
    duration:0, 
    message:'', 
    serverity:''
  });

  const globalData = new AppGlobalStaticData();

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios.get(`${AppBaseUrl}/api/v1/court/${id}/get?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`)
        .then(response => {
          if (response.data.status === 'Success') {
            setCourt(response.data.data);
            
          } else {
            setSnackbarProperties({
              duration:globalData.Duration, 
              message:globalData.FetchCaseFailed, 
              serverity:globalData.SeverityError
            });
            console.error('Failed to fetch court details');
          }
        })
        .catch(error => {
          setSnackbarProperties({
            duration:globalData.Duration, 
            message:globalData.FetchCaseFailed, 
            serverity:globalData.SeverityError
          });
          console.error('Error fetching court details:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const handleChange = (e) => {
    setCourt({
      ...court,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const submitRequest = id
      ? axios.put(`${AppBaseUrl}/api/v1/court/${id}/update?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`, court)
      : axios.post(`${AppBaseUrl}/api/v1/court/create?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`, court);

    submitRequest
      .then(response => {
        if (response.data.status === 'Success') {
          onClose();
          setSnackbarProperties({
            duration:globalData.Duration, 
            message:id  ? globalData.EditCourtSuccess : globalData.AddCourtSuccess, 
            serverity:globalData.SeveritySuccess
          });
        } else {
          setSnackbarProperties({
            duration:globalData.Duration, 
            message:id  ? globalData.EditCourtFailed : globalData.AddCourtFailed,
            serverity:globalData.SeverityError
          });
          console.error(`Failed to ${id ? 'update' : 'create'} court`);
        }
      })
      .catch(error => {
        setSnackbarProperties({
          duration:globalData.Duration, 
          message:id  ? globalData.EditCourtFailed : globalData.AddCourtFailed,
          serverity:globalData.SeverityError
        });
        console.error(`Error ${id ? 'updating' : 'creating'} court:`, error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Court Name"
            name="CourtName"
            value={court.CourtName}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Place"
            name="Place"
            value={court.Place}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              {id ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <AutohideSnackbar  duration={snackbarProperties.duration} message={snackbarProperties.message} severity={snackbarProperties.serverity} ></AutohideSnackbar>
    </form>
  );
};

export default CourtForm;
