import React, { useEffect } from "react";
import CaseList from '../components/CaseList';
import {
  Container,
  Typography,
  Box
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const DisposedDecreedListPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = Cookies.get('auth_token');
    if (!token) {
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  }, [navigate]);

  const enddate = '';
  const startDate = '';
  const casestatus = [1, 2];

  return (
    <Container>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
        mb={2}
      >
        <Typography variant="h4" gutterBottom>
          Disposed-Decreed Case List
        </Typography>
      </Box>
      <CaseList Type={"Disposed-Decreed"} startdate={startDate} enddate={enddate} casestatusParam={casestatus} />
    </Container>
  );
};

export default DisposedDecreedListPage;
