// src/pages/CourtForm.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TextField, Button, CircularProgress, Box, Grid } from '@mui/material';
import AutohideSnackbar from './autohideSnackbar';
import {AppBaseUrl} from '../appdata/appData';
import AppGlobalStaticData from '../appdata/appStaticData';

const UserForm = ({ id, onClose }) => {
  const [user, setUser] = useState({
    name: '',
    email:'',
    password:''
  });
  const [loading, setLoading] = useState(false);
  const [snackbarProperties,  setSnackbarProperties] = useState({
    duration:0, 
    message:'', 
    serverity:''
  });

  const globalData = new AppGlobalStaticData();

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios.get(`${AppBaseUrl}/api/v1/user/${id}/get?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`)
        .then(response => {
          if (response.data.status === 'Success') {
            setUser(response.data.data);
          } else {
            setSnackbarProperties({
              duration:globalData.Duration, 
              message:globalData.FetchUserFailed, 
              serverity:globalData.SeverityError
            });
          }
        })
        .catch(error => {
          setSnackbarProperties({
            duration:globalData.Duration, 
            message:globalData.FetchUserFailed, 
            serverity:globalData.SeverityError
          });
          console.error('Error fetching court details:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };



  const handleSubmit = async(e) => {
    e.preventDefault();
    setLoading(true);

    const submitRequest = id
      ?  axios.put(`${AppBaseUrl}/api/v1/user/${id}/update?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`, user)
      :  axios.post(`${AppBaseUrl}/api/v1/user/create?api_token=ExU4PwIELVlZRx54ly/SOhZRilxHvyQHHo`, user);

    submitRequest
      .then(response => {
        if (response.data.status === 'Success') {
          onClose();
          setSnackbarProperties({
            duration:globalData.Duration, 
            message:id  ? globalData.EditUserSuccess : globalData.AddUserSuccess, 
            serverity:globalData.SeverityError
          });
        } else {
          setSnackbarProperties({
            duration:globalData.Duration, 
            message:id  ? globalData.EditUserFailed : globalData.AddUserFailed , 
            serverity:globalData.SeverityError
          });
        }
      })
      .catch(error => {
        setSnackbarProperties({
          duration:globalData.Duration, 
          message:id  ? globalData.EditUserFailed : globalData.AddUserFailed , 
          serverity:globalData.SeverityError
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };




  if (loading) {
    return <CircularProgress />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            name="name"
            value={user.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email"
            name="email"
            value={user.email}
            onChange={handleChange}
            fullWidth
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Password"
            name="password"
            value={user.password}
            onChange={handleChange}
            fullWidth
             type="password"
            margin="normal"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              {id ? 'Update' : 'Create'}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <AutohideSnackbar  duration={snackbarProperties.duration} message={snackbarProperties.message} severity={snackbarProperties.serverity} ></AutohideSnackbar>
    </form>
  );
};

export default UserForm;
