// src/pages/UserListPage.js
import React, { useEffect } from "react";
import UserList from '../components/UserList';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const UserListPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const token = Cookies.get('auth_token');
    if (!token) {
      setTimeout(() => {
        navigate("/login");
      },1000);
    }
  }, [navigate]);

  return <UserList />;
};

export default UserListPage;
